<template>
  <v-container class="pa-0 container">
    <h1 class="px-2 py-4 text-center">🤔</h1>
    <v-list
      two-line
      subheader
      class="list"
    >
      <v-list-item v-for="(item, index) in data" :key="index" :disabled="item.status === 'pending'" :to="`/video/${index}`">
        <v-list-item-content>
          <v-list-item-title class="list-title">
            <span v-if="item.status === 'pending'" class="pending">还在摸</span>
            <v-icon small color="grey" class="pr-1" v-if="learnt(index) && item.status !== 'pending'">mdi-check</v-icon>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import * as data from '../data.json'

export default {
  name: 'Home',
  data: () => ({
    data: data.data,
    learntList: []
  }),
  methods: {
    learnt (index) {
      if (this.learntList.includes(index)) {
        return true
      }
      if (localStorage.getItem(`learnt-${index}`) === 'true') {
        this.learntList.push(index)
        return true
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  max-width: 600px;
  height: 100vh;
  background-color: #F5F5F5;
  overflow: auto;
  .list {
    background-color: transparent;
    .list-title {
      display: flex;
      align-items: center;
      .pending {
        background-color: #999999;
        color: white;
        font-size: 12px;
        display: inline-block;
        padding: 2px 4px 1px 4px;
        border-radius: 3px;
        margin-right: 5px;
      }
    }
  }
}
</style>
